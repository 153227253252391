import type {
  CommonFields,
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { Mixpanel } from 'mixpanel-browser'

import { FacebookEventHandler } from './FacebookEventHandler.ts'
import { GoogleEventHandler } from './GoogleEventHandler.ts'
import { MixpanelEventHandler } from './MixpanelEventHandler.ts'
import type {
  XnsEstimateAttemptedFields, XnsEstimateFailureFields, XnsEstimateSuccessFields,
} from './XnsEstimateEventHandlerInterface.ts'
import type {
  XnsPurchaseAttemptedFields, XnsPurchaseFailureFields, XnsPurchaseSuccessFields,
} from './XnsPurchaseEventHandlerInterface.ts'
import type {
  XnsReservationAttemptedFields, XnsReservationFailureFields, XnsReservationSuccessFields,
} from './XnsReservationEventHandlerInterface.ts'
import { XyoEventHandler } from './XyoEventHandler.ts'
import type { XyoEventHandlerInterface, XyoNewsletterSignupFields } from './XyoEventHandlerInterface.ts'

export class XyoUserEvents<TData extends CommonFields = CommonFields> extends UserEventHandler<TData> {
  static instance: XyoUserEvents<CommonFields>

  protected handlers: XyoEventHandlerInterface<TData>[]

  private constructor(handlers?: XyoEventHandlerInterface<TData>[]) {
    super()
    this.handlers = handlers ?? [new XyoEventHandler<TData>(), new FacebookEventHandler<TData>(), new GoogleEventHandler<TData>()]
  }

  static get<T extends CommonFields = CommonFields>(mixpanel?: Mixpanel) {
    this.instance = this.instance ?? (() => {
      const handlers = [new XyoEventHandler<T>(), new FacebookEventHandler<T>(), new GoogleEventHandler<T>()]
      if (mixpanel) handlers.push(new MixpanelEventHandler(mixpanel))
      return new XyoUserEvents<T>(handlers)
    })()
    return this.instance
  }

  funnelStarted<T extends FunnelStartedFields>(_fields: T): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted<T extends TestStartedFields>(_fields: T): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick<T extends UserClickFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.userClick(fields)))
  }

  async viewContent<T extends ViewContentFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.viewContent(fields)))
  }

  async xnsEstimateAttempted<T extends XnsEstimateAttemptedFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsEstimateAttempted(fields)))
  }

  async xnsEstimateFailure<T extends XnsEstimateFailureFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsEstimateFailure(fields)))
  }

  async xnsEstimateSuccess<T extends XnsEstimateSuccessFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsEstimateSuccess(fields)))
  }

  async xnsPurchaseAttempted<T extends XnsPurchaseAttemptedFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsPurchaseAttempted(fields)))
  }

  async xnsPurchaseFailure<T extends XnsPurchaseFailureFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsPurchaseFailure(fields)))
  }

  async xnsPurchaseSuccess<T extends XnsPurchaseSuccessFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsPurchaseSuccess(fields)))
  }

  async xnsReservationAttempted<T extends XnsReservationAttemptedFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsReservationAttempted(fields)))
  }

  async xnsReservationFailure<T extends XnsReservationFailureFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsReservationFailure(fields)))
  }

  async xnsReservationSuccess<T extends XnsReservationSuccessFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xnsReservationSuccess(fields)))
  }

  async xyoNewsletterSignup<T extends XyoNewsletterSignupFields>(fields: T) {
    await Promise.all(this.handlers.map(handler => handler.xyoNewsletterSignup(fields)))
  }
}
